<template>
  <v-row>
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.path"
        :href="'#' + tab.path"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="custom-tab-items"
    >
      <v-tab-item value="all">
        <all-analytics />
      </v-tab-item>
      <v-tab-item
        v-for="station in stations"
        :key="station.id"
        :value="station.id"
      >
        <all-analytics
          :station-id="station.id"
        ></all-analytics>
      </v-tab-item>
    </v-tabs-items>
  </v-row>
</template>

<script>
import axios from 'axios'
import AllAnalytics from './AllAnalytics.vue'

export default {
  name: 'PaymentAnalytics',
  components: {
    AllAnalytics,
  },
  data() {
    return {
      tab: null,
      tabs: [
        {
          title: 'All',
          path: 'all',
        },
      ],
      stations: [],
      years: [],
    }
  },
  mounted() {
    this.getStations()
  },
  methods: {
    getStations() {
      axios.get('stations?perPage=all').then(response => {
        const stations = response.data
        this.stations = stations
        stations.forEach(stations => {
          this.tabs.push({
            title: stations.name,
            path: stations.id,
          })
        })
      })
    },
  },
}
</script>

<style scoped>
.custom-tab-items {
    background-color: transparent !important;
}
</style>
