<template>
  <div>
    <div
      v-if="isRevenueLoading"
      class="d-flex justify-center align-center"
      style="height: 100%;"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div id="chart">
      <apexchart
        v-show="!isRevenueLoading & revenueBarGraphData.xaxis.categories.length > 0"
        ref="chart"
        type="bar"
        :options="revenueBarGraphData"
        :series="revenueBarGraphData.series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    isRevenueLoading: {
      type: Boolean,
      default: true,
    },
    revenueBarGraphData: {
      type: Object,
      default: () => ({
        chart: {
          toolbar: {
            show: false,
          },
          type: 'bar',
        },
        xaxis: {
          categories: [],
        },
        series: [{
          name: 'Revenue (Ksh)',
          data: [],
        }],
      }),
    },
  },

}
</script>
