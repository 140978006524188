<template>
  <v-row
    class="match-height mt-4"
  >
    <v-col
      cols="12"
      md="3"
    >
      <statistics-card
        :name="'Total Internet Plans'"
        :value="InternetPlanCount"
        :is-loading="isInternetPlanCountLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <statistics-card
        :name="'Most Popular Plan'"
        :value="mostPopularInternetPlan"
        :is-loading="isMostPopularInternetPlanLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <statistics-card
        :name="'Least Popular Plan'"
        :value="leastPopularInternetPlan"
        :is-loading="isLeastPopularInternetPlanLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <statistics-card
        :name="'Average Revenue Per Customer'"
        :value="averageRevenuePerUser"
        :is-loading="isAverageRevenuePerUserLoading"
      />
    </v-col>
    <v-col cols="12">
      <v-card min-height="475">
        <v-card-title>
          <v-col cols="12">
            Number of Customers Per Internet Plan
          </v-col>
        </v-card-title>
        <v-tabs
          v-model="internetConnectionType"
          show-arrows
        >
          <v-tab
            v-for="internetConnectionType in internetConnectionTypes"
            :key="internetConnectionType.path"
            :href="'#' + internetConnectionType.path"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ internetConnectionType.icon }}
            </v-icon>
            <span>{{ internetConnectionType.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="internetConnectionType"
        >
          <v-tab-item
            v-for="internetConnectionType in internetConnectionTypes"
            :key="internetConnectionType.path"
            :value="internetConnectionType.path"
          >
            <v-card-text>
              <customer-count-bar-graph
                :is-revenue-loading="isCustomerPerInternetPlanGraphDataLoading"
                :revenue-bar-graph-data="revenueBarGraphData"
              />
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import StatisticsCard from './components/StatisticsCard.vue'
import CustomerCountBarGraph from './components/CustomerCountBarGraph.vue'
import calculateBarWidth from '@/mixins/calculateBarWidth'

export default {
  components: { StatisticsCard, CustomerCountBarGraph },
  mixins: [calculateBarWidth],
  props: {
    stationId: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    isInternetPlanCountLoading: true,
    isMostPopularInternetPlanLoading: true,
    isLeastPopularInternetPlanLoading: true,
    isAverageRevenuePerUserLoading: true,
    isCustomerPerInternetPlanGraphDataLoading: true,
    isPerStationRevenueBarGraphLoading: true,
    InternetPlanCount: 0,
    mostPopularInternetPlan: 0,
    leastPopularInternetPlan: 0,
    averageRevenuePerUser: 0,
    internetConnectionType: 1,
    internetConnectionTypes: [
      {
        title: 'PPPOE',
        path: '1',
      },
      {
        title: 'STATIC',
        path: '3',
      },
      {
        title: 'DYNAMIC',
        path: '2',
      },
      {
        title: 'HOTSPOT',
        path: '4',
      },
    ],
    revenueBarGraphData: {
      chart: {
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: [],
      },
      series: [{
        name: 'Revenue (Ksh)',
        data: [],
      }],
    },
    perStationRevenueBarGraphData: {
      chart: {
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: [],
      },
      series: [{
        name: 'Revenue (Ksh)',
        data: [],
      }],
    },
    selectedAllRevenueYear: new Date().getFullYear(),
    selectedPerStationRevenueYear: new Date().getFullYear(),
  }),
  mounted() {
    this.getInternetPlanCount()
    this.getMostPopularInternetPlan()
    this.getLeastPopularInternetPlan()
    this.getAverageRevenuePerUser()
    this.getCustomerPerInternetPlanGraphData()
  },
  watch: {
    internetConnectionType() {
      this.getCustomerPerInternetPlanGraphData()
    },
  },
  methods: {
    getInternetPlanCount() {
      this.isInternetPlanCountLoading = true
      axios
        .get(`analytics/internet-plans/internet-plan-count?stationId=${this.stationId}`)
        .then(response => {
          this.InternetPlanCount = response.data
          this.isInternetPlanCountLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isInternetPlanCountLoading = false
        })
    },
    getMostPopularInternetPlan() {
      this.isMostPopularInternetPlanLoading = true
      axios
        .get(`analytics/internet-plans/most-popular-internet-plan?stationId=${this.stationId}`)
        .then(response => {
          this.mostPopularInternetPlan = response.data.name
          this.isMostPopularInternetPlanLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isMostPopularInternetPlanLoading = false
        })
    },
    getLeastPopularInternetPlan() {
      this.isLeastPopularInternetPlanLoading = true
      axios
        .get(`analytics/internet-plans/least-popular-internet-plan?stationId=${this.stationId}`)
        .then(response => {
          this.leastPopularInternetPlan = response.data.name
          this.isLeastPopularInternetPlanLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLeastPopularInternetPlanLoading = false
        })
    },
    getAverageRevenuePerUser() {
      this.isAverageRevenuePerUserLoading = true
      axios
        .get(`analytics/internet-plans/average-revenue-per-customer?stationId=${this.stationId}`)
        .then(response => {
          this.averageRevenuePerUser = `Ksh ${Math.round(response.data.average_revenue)}`
          this.isAverageRevenuePerUserLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isAverageRevenuePerUserLoading = false
        })
    },
    getCustomerPerInternetPlanGraphData() {
      this.isCustomerPerInternetPlanGraphDataLoading = true
      axios
        .get(`analytics/internet-plans/customer-per-internet-plan-graph-data?stationId=${this.stationId}&internetConnectionType=${this.internetConnectionType}`)
        .then(response => {
          const { data } = response
          const chartLabels = data.labels
          const chartValues = data.data

          this.revenueBarGraphData = {
            ...this.revenueBarGraphData,
            ...{
              xaxis: {
                categories: chartLabels,
                title: {
                  text: 'Number of Customers',
                },
                labels: {
                  formatter(value) {
                    return parseInt(value, 10)
                  },
                },
              },
              series: [{
                name: 'Number of Customers',
                data: chartValues,
              }],
              tooltip: {
                y: {
                  formatter(value) {
                    return parseInt(value, 10)
                  },
                },
              },

              plotOptions: {
                bar: {
                  horizontal: true,
                  columnWidth: `${this.calculateBarWidth(chartLabels.length)}%`,
                  endingShape: 'rounded',
                },
              },
            },
          }
          this.isCustomerPerInternetPlanGraphDataLoading = false
        })
        .catch(error => {
          this.isCustomerPerInternetPlanGraphDataLoading = false
          console.log(error)
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
