<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="revenue-title">{{ name }}</span>
    </v-card-title>

    <v-card-text v-show="isLoading">
      <statistics-card-shimmer />
    </v-card-text>

    <v-card-text v-show="!isLoading">
      <div class="d-flex align-center">
        <h2 class="text-2xl font-weight-semibold revenue-amount">
          {{ formattedValue }}
        </h2>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMenuUp, mdiMenuDown } from '@mdi/js'
import StatisticsCardShimmer from './StatisticsCardShimmer.vue'

export default {
  filters: {
    positive(value) {
      return Math.abs(value)
    },
  },
  components: {
    StatisticsCardShimmer,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number], // Accepts both strings and numbers
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      icons: { mdiMenuUp, mdiMenuDown },
    }
  },
  computed: {
    formattedValue() {
      if (typeof this.value === 'number') {
        return this.value.toLocaleString() // Format numbers with commas
      }

      return this.value // Return strings as is
    },
  },
}
</script>

<style>
.revenue-title {
  font-size: 18px;
}

.revenue-amount {
  font-size: 28px;
  font-weight: bold;
}

.revenue-icon {
  margin-right: 8px;
}

.success--text {
  color: var(--success); /* Use Vuetify's success color */
}

.error--text {
  color: var(--error); /* Use Vuetify's error color */
}
</style>
